import React from "react";
import { Menu, MenuProps } from "antd";
import Link from "next/link";

import { useUser } from "@auth0/nextjs-auth0";

const { SubMenu } = Menu;

export const SideMenu: React.FC<MenuProps> = () => {
    const { user } = useUser();

    return (
        <Menu theme="dark" mode="inline">
            <Menu.Item key="side-menu-item-home">
                <Link href={"/"} data-testid="side-menu-home">
                    <a className="nav-link">Home</a>
                </Link>
            </Menu.Item>
            {user && (
                <>
                    <SubMenu
                        key="sub-menu-browse-wardrobe"
                        title="Browse Wardrobe"
                    >
                        <Menu.Item key="side-menu-item-wardrobe-items">
                            <Link
                                href={"/items"}
                                data-testid="side-menu-wardrobe-items"
                            >
                                <a className="nav-link">Wardrobe Items</a>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="side-menu-item-outfits">
                            <Link
                                href={"/outfits"}
                                data-testid="side-menu-outfits"
                            >
                                <a className="nav-link">Outfits</a>
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu key="sub-menu-settings" title="Setings">
                        <Menu.Item key="side-menu-item-stores">
                            <Link
                                href={"/stores"}
                                data-testid="side-menu-my-stores"
                            >
                                <a className="nav-link">My Stores</a>
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                </>
            )}
        </Menu>
    );
};
