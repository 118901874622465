import React from "react";
import { Avatar, Menu, MenuProps, Dropdown } from "antd";
import Link from "next/link";

import { useUser } from "@auth0/nextjs-auth0";

export const TopMenu: React.FC<MenuProps> = () => {
    const { user, isLoading } = useUser();

    return (
        <Menu theme="dark" mode="horizontal">
            {!isLoading && !user && (
                <span style={{ marginLeft: "auto" }}>
                    <Menu.Item key="top-menu-item-login">
                        <Link
                            href={"/api/auth/login"}
                            data-test-id="top-menu-login"
                        >
                            <a className="btn btn-primary btn-margin">
                                {" "}
                                Log in{" "}
                            </a>
                        </Link>
                    </Menu.Item>
                </span>
            )}
            {user && (
                <span style={{ marginLeft: "auto" }}>
                    <Dropdown
                        placement="topRight"
                        trigger={["hover"]}
                        overlay={
                            <Menu>
                                <Menu.ItemGroup title={user.name}>
                                    <Menu.Item key="top-menu-item-profile">
                                        <Link href={"/profile"}>
                                            <a>Profile</a>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="top-menu-item-logout">
                                        <Link href={"/api/auth/logout"}>
                                            <a>Log Out</a>
                                        </Link>
                                    </Menu.Item>
                                </Menu.ItemGroup>
                            </Menu>
                        }
                    >
                        <Avatar src={user.picture} />
                    </Dropdown>
                </span>
            )}
        </Menu>
    );
};
