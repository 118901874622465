import React from "react";

import { TopMenu } from "@components/TopMenu";
import { SideMenu } from "@components/SideMenu";
import { AppProps } from "next/app";
import { UserProvider } from "@auth0/nextjs-auth0";
import { Layout } from "antd";

const { Header, Content, Sider } = Layout;

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
    return (
        <UserProvider>
            <Layout style={{ height: "100%" }}>
                <Sider
                    width={200}
                    style={{
                        overflow: "auto",
                        height: "100vh",
                        position: "fixed",
                        left: 0,
                    }}
                >
                    <SideMenu />
                </Sider>

                <Layout
                    className="site-layout"
                    style={{ marginLeft: 200, height: "100vh" }}
                >
                    <Header className="header">
                        <div className="logo" />
                        <TopMenu />
                    </Header>
                    <Content
                        style={{ margin: "24px 16px 0", overflow: "initial" }}
                    >
                        <Component {...pageProps} />
                    </Content>
                </Layout>
            </Layout>
        </UserProvider>
    );
}

export default MyApp;
